$bb-menu-pink: var(--bb-theme-color);
$bb-menu-pink-rgb: var(--bb-theme-color-rgb);

$hoverOpacity: 0.5;

  /* Showcase menu */ 
  .menu-dropdown{
      width: 210px;
      transition: all 0.3s;
  }

  .bb-menu-container{
    transition: all 1s;
    //min-height: 100px;
  }

  .bb-showcase-menu {
    //border: 1px solid #858585;
    color: var(--bb-text-color-white);
    //transform: translateX(120%);
    display: none;
    transition: all 0.3s;
    width: 210px;
    animation: fadein 0.35s ease-in;
    &__heading{
        &:active, &:focus{
            outline: none;
        }
    }
  
    a {
        color: var(--bb-theme-color);
    }
  
    &__item{ 
        width: 210px;
    } 
  
    &__button {
        cursor: pointer;
        padding: 10px 10px 10px;
        text-align: left;
        border: none;
        position: relative;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;      
    
      &:active, &:focus{
          outline: none;
      }
      &:hover{
        background-color: rgba($bb-menu-pink-rgb, $hoverOpacity);
      }
    
      
      &:after{
          display: inline-block;
          content: '';
          height: 10px;
          width: 10px;
          //margin-left: 105px;
          border-bottom: 2px solid #fff;
          border-right: 2px solid #fff;
          transform: rotate(-45deg);
          transition: all 0.35s;
      } 
    
      &[aria-expanded='true']::after,&[aria-selected='true']::after{
          transform: rotate(45deg);
          margin-bottom: 4px;
      } 
     
    }
    &__panel {
      animation: fadein 0.35s ease-in;
      font-size: 14px !important;
        //@extend: 5px 5px 5px;
        //margin-left: 30px;
        text-align: left;
        margin-top: 5px;
        cursor: pointer;

        label>input[type="radio"], label>input[type="checkbox"] {
            display: none;
            cursor: pointer;
        }
    }

    &__sub-item{
            background: transparent;
            border: none;
            font-size: 14px;
            padding: 2px;
            cursor: pointer;        
    }

    label.list-group-item {
        background: transparent;
        border: none;
        font-size: 14px;
        padding: 0px 0px 0px 15px;
        cursor: pointer;
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
       
        &.active::before {
          font-family: "FontAwesome";
          content: "\f00c";
          color: var(--bb-blue-color);
          //height: 16px;
          //width: 8px;
          //float: left;
          margin-right: 5px;
          //border-radius: 5px;
          display: block;
          -webkit-animation-name: swing;
          animation-name: swing;
          -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
          -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
          }          
          &:hover:not(.active) {
            background-color: rgba($bb-menu-pink-rgb, $hoverOpacity);
          }
    }
    a{
      color: white;
      font-size: 14px;
      padding: 0px;
    
  }
  
    /* label.list-group-item.active {
        background: transparent;
        background: rgba(255, 255, 255, 0.8);
        color: #e61256;
    } */
    
    label.list-group-item:hover {
        background-color: rgba($bb-menu-pink-rgb, $hoverOpacity);
    }
  
  }
  
  /* -------------------------------------------------- */
  /* ---------------- Animation part ------------------ */
  /* -------------------------------------------------- */
  
  @keyframes fadein {
    0% {
        opacity: 0;
    }
  
    100% {
        opacity: 1;
    }
  }